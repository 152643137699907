import React from 'react'
import HeroBanner from "../../components/Common Banner Component/HeroBanner";

const content = {
    heroHeading:"COMPREHENSIVE STRUCTURED CABLING",
    mainContent:[
        {
            heading: "Client Overview",
            para: "Tim Hortons, Canada’s largest coffee chain, serves customers nationwide and has recently expanded into the U.S. In its fast-paced, high-traffic locations, efficient communication and modern screens are essential to maintaining smooth operations and delivering a positive, informed customer experience."
        },
        {
            heading: "Problem",
            para: "With its ever-growing business, Tim Hortons is renovating locations across the country, updating everything from the ground up with new furnishings and designs, embracing the modern age. While many elements were in place, unfortunately, the networking infrastructure wasn’t. Without a proper network connection, the screens, cash registers, drive-thru, POS systems, and more wouldn't function. That's where Blue Fysh comes in."
        },
        {
            heading: "Solution",
            para: "A couple of our technicians arrived on-site and ran cabling throughout the entire building for all necessary devices, ensuring a clean and organized setup. They set up in the network closet, running cables to the designated spots and properly terminating them for connection. This brought the space to life with functionality, delivering data to registers, screens, drive-thrus, and more, one of the key steps in modernizing a quick-service restaurant."
        },
        {
            heading: "Implementation",
            para: "Cat5 cables were run to ensure fast connections that support modern data speeds and loads. The ends were terminated with high-speed RJ45 connectors, ensuring data transfer won't become a bottleneck. These cables were routed to the network closet, where they were connected directly to a panel for easy plug-in. This location now features a tailored network setup with plug-in spots throughout the building, providing both fast speeds and ease of future maintenance."
        },
        {
            heading: "Resolution",
            para: "By the time the technicians were done, everything was in place and ready to be plugged in. Once the hardware arrives, it will connect directly to its endpoints, neatly organized, and link to the network. From there, the system will be controlled from the data center, enabling efficient display messaging and targeted advertising."
        },
        {
            heading: "Conclusion",
            para: "This space is now well-equipped for the fast-paced environment of quick-service restaurants, ensuring that whether it's a morning coffee run or a busy lunch rush, the location will never encounter network issues. With modern technology, a clean setup, and fast communication, the system ensures seamless and efficient interaction between hardware and staff.",
            vid:"/images/caseStudy/cabling/vid-1.mp4"
        }
    ]
    
}

function StructuredCabling() {
  return (
          <>
            <div >
              
              <HeroBanner
                Paragraph={``}
                Heading={content.heroHeading}
                HighlightedSpan={""}
                isBtn={false}
                bgImg={'/images/creatives/inner_case_st_banner.jpg'}
                isChevron={true}
            />
              <div className='main-content standard-padding-space'>
                <div className='container'>
                    {
                        content.mainContent.map((ele)=>{
                            return(
                                <div className='pb-4'>
                                <h2 className="body-heading ">{ele.heading}</h2>
                                <p className="body-paragraph">{ele.para}</p>
                                {
                                    ele.img &&
                                    <div className='w-100 text-center pt-3 pb-5'>
                                        <img src={ele.img} alt='thumbnail' className='img-fluid w-75'/>
                                    </div>
                                }
                                {
                                    ele.vid &&
                                    <div className='w-100 text-center'>
                                         <video autoPlay loop muted controls playsInline style={{width:"70%"}} controlslist="nodownload" >
                                            <source src={ele.vid} type="video/mp4" />
                                        </video>
                                    </div>
                                }
                                </div>
                            )
                        })
                    }

                </div>
              </div>
            </div>
          </>
  )
}

export default StructuredCabling