import React from 'react'
import HeroBanner from "../../components/Common Banner Component/HeroBanner";

const content = {
    heroHeading:"EDUCATION, FINANCE & HEALTH CARE",
    mainContent: [
        {
            heading: "Client Overview",
            para: "BMO, one of Canada's largest banks and a member of the Big 5, was founded in 1817. It offers a wide range of services, including personal, commercial, investment banking, and wealth management, operating in both Canada and the U.S. through BMO Harris Bank. Known for its innovation in digital banking and strong community focus, BMO continues to lead in modern banking solutions.",
            para1: "When opening a new location at Dundas Square, Canada’s equivalent of Times Square, BMO wanted a sleek, modern 3D wall with immersive visuals to showcase its commitment to customization, versatility, and brand differentiation. As the first bank in Canada to implement a wall of this scale, it reflects BMO’s dedication to staying at the forefront of banking and technology."
        },
        {
            heading: "Problem",
            para: "The challenge was that this would be the largest installation of its kind in North America. There was no room for error—everything needed to run flawlessly to reinforce BMO's identity as a confident, reliable institution."
        },
        {
            heading: "Solution",
            para: "The solution was for Blue Fysh to collaborate closely with all parties, such as Muxweave, a screen technology leader, and BMO, which directed the project’s creative vision. Blue Fysh brought in our top technicians from across North America, ensuring a seamless project experience with no delays, delivering ahead of schedule."
        },
        {
            heading: "Implementation",
            para: "Blue Fysh thoroughly reviewed all documentation and collaborated closely with the supplier to fully understand the implementation process. By working with multiple partners, we ensured a smooth installation experience for everyone involved. The installation proceeded seamlessly, thanks to the collaboration of Blue Fysh and our top team members, ensuring perfect execution and clear communication, key pillars of our mission and identity."
        },
        {
            heading: "Resolution",
            para: "Upon completion, the new BMO building at Dundas Square will be the crown jewel of the area. Its attention-grabbing design effectively communicates promotions and messaging to everyone nearby. The modern technology captivates onlookers, allowing them to see inside the building while simultaneously displaying high-resolution media on a large-scale, a transparent screen. This futuristic viewing experience beautifully complements BMO's identity and mission."
        },
        {
            heading: "Conclusion",
            para: "Blue Fysh will continue to work with BMO and Muxweave to ensure the displays run smoothly and communicate messaging effectively. As the go-to repair team, we will minimize downtime and maintain seamless 24/7 high-tech displays, reinforcing BMO's modern identity and showcasing Blue Fysh's commitment to being a leading provider of display and networking solutions."
        }
    ]    
}

function Education() {
  return (
          <>
            <div >
              
              <HeroBanner
                Paragraph={``}
                Heading={content.heroHeading}
                HighlightedSpan={""}
                isBtn={false}
                bgImg={'/images/creatives/inner_case_st_banner.jpg'}
                isChevron={true}
            />
              <div className='main-content standard-padding-space'>
                <div className='container'>
                    {
                        content.mainContent.map((ele)=>{
                            return(
                                <div className='pb-4'>
                                <h2 className="body-heading ">{ele.heading}</h2>
                                <p className="body-paragraph">{ele.para}</p>
                                {
                                    ele.para1 &&
                                    <p className="body-paragraph">{ele.para1}</p>
                                }
                                {
                                    ele.img &&
                                    <div className='w-100 text-center pt-3 pb-5'>
                                        <img src={ele.img} alt='thumbnail' className='img-fluid w-75'/>
                                    </div>
                                }
                                </div>
                            )
                        })
                    }

                </div>
              </div>
            </div>
          </>
  )
}

export default Education