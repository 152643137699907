import React from 'react'
import HeroBanner from "../../components/Common Banner Component/HeroBanner";

const content = {
    heroHeading:"FAST FOOD & HOSPITALITY",
    mainContent: [
        {
            heading: "Client Overview",
            para: "Harvey’s, founded in 1959, is one of Canada’s largest fast-food burger chains, renowned for its emphasis on customization. By offering fresh ingredients and a personalized experience, it sets itself apart in the industry. However, this approach also opens the door to numerous communication opportunities and potential challenges.",
            img:"/images/caseStudy/food/2.jpg"
        },
        {
            heading: "Problem",
            para: "The problem lies in outdated screens that struggle to deliver information quickly, hindering the rollout of new offerings. This issue is compounded by an unstable network connection and poor display quality, which negatively impact the brand’s image, the perception of its locations, and the quality of its ingredients."
        },
        {
            heading: "Solution",
            para: "The solution is to upgrade the space with modern, high-resolution screens connected to a fast and stable network. This setup ensures quicker and more reliable information rollouts. The improved screens enhance messaging efficiency, reducing downtime and increasing customer retention, both indoors and outdoors.",
            img:"/images/caseStudy/food/3.jpg"
        },
        {
            heading: "Implementation",
            para: "At this location, we replaced all indoor screens to give the space a complete digital facelift. The new screens are more vibrant, crisper, and feature higher refresh rates. Paired with upgraded network equipment, they enable faster communication between the hardware and the messaging customers see, enhancing the overall experience for burger-loving patrons.",
            img:"/images/caseStudy/food/5.jpg"
        },
        {
            heading: "Resolution",
            para: "The space is now equipped to deliver a state-of-the-art display experience to its customers. With cutting-edge refresh rates, vibrant colors, and seamless motion, the location stands out, thanks to Blue Fysh and our expert installation team.",
            img:"/images/caseStudy/food/4.jpg"
        },
        {
            heading: "Conclusion",
            para: "Updating this space has brought Harvey’s into the modern age, enhancing its brand image with state-of-the-art menu displays that captivate attention and communicate effectively. For a chain offering so many options, fast, clear, and data-driven communication is key to better targeting and driving sales.",
            img:"/images/caseStudy/food/1.jpg"
        }
    ]    
}

function FoodAndHospitality() {
  return (
          <>
            <div >
              
              <HeroBanner
                Paragraph={``}
                Heading={content.heroHeading}
                HighlightedSpan={""}
                // blueHiglightedText={content.category1}
                isBtn={false}
                bgImg={'/images/creatives/inner_case_st_banner.jpg'}
                isChevron={true}
            />
              <div className='main-content standard-padding-space'>
                <div className='container'>
                    {
                        content.mainContent.map((ele)=>{
                            return(
                                <div className='pb-4'>
                                <h2 className="body-heading ">{ele.heading}</h2>
                                <p className="body-paragraph">{ele.para}</p>
                                {
                                    ele.img &&
                                    <div className='w-100 text-center pt-3 pb-5'>
                                        <img src={ele.img} alt='thumbnail' className='img-fluid w-75'/>
                                    </div>
                                }
                                </div>
                            )
                        })
                    }

                </div>
              </div>
            </div>
          </>
  )
}

export default FoodAndHospitality