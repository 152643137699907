import React from 'react'
import HeroBanner from "../../components/Common Banner Component/HeroBanner";

const content = {
    heroHeading:"SPECIALIZED SECTORS",
    mainContent: [
        {
            heading: "Client Overview",
            para: "Nespresso, a globally recognized brand owned by Nestlé, is known for its coffee machines and single-serve beverages. Since its launch in the 1980s, it has revolutionized the coffee industry by making it effortless to brew quality coffee with just a machine and a capsule."
        },
        {
            heading: "Problem",
            para: "Nestlé sought to increase client awareness of its products, offerings, and varieties through a digital approach in its stores, using modern displays designed to capture attention with vibrant colors and effectively inform customers."
        },
        {
            heading: "Solution",
            para: "Blue Fysh supported this mission by updating and refurbishing the location with modern displays, enabling Nestlé to advertise and engage with clients in higher resolution. This enhancement brought their space into the modern era, positively reflecting the brand image of their coffee products.",
            img:"/images/caseStudy/commercial/2.jpg"
        },
        {
            heading: "Implementation",
            para: "Blue Fysh installed in-wall displays that deliver high-resolution information about Nestlé's products and offerings, creating a shopping experience that aligns with modern expectations. These displays provide clients with easy access to information, enabling informed decisions and fostering a seamless, contemporary experience in Nescafé spaces. This implementation reflects the brand's commitment to quality, efficiency, speed, and convenience."
        },
        {
            heading: "Resolution",
            para: "The space has been fully updated, allowing customers to engage with the modern displays. As paper becomes obsolete, the high-resolution modular displays capture clients' attention with vibrant colors and sharpness, keeping them engaged longer. Additionally, these displays facilitate quicker updates and more targeted information delivery, creating a fast-paced work environment ready for adoption."
        },
        {
            heading: "Conclusion",
            para: "This update has modernized the shop, elevating it to a top-tier shopping destination. The enhanced experience not only informs customers and facilitates higher sales through an engaging and user-friendly approach but also helps to strengthen and reflect the brand's image moving forward.",
            img:"/images/caseStudy/commercial/1.jpg"
        }
    ]    
}

function SpecializedSector() {
  return (
          <>
            <div >
              
              <HeroBanner
                Paragraph={``}
                Heading={content.heroHeading}
                HighlightedSpan={""}
                // blueHiglightedText={content.category1}
                isBtn={false}
                bgImg={'/images/creatives/inner_case_st_banner.jpg'}
                isChevron={true}
            />
              <div className='main-content standard-padding-space'>
                <div className='container'>
                    {
                        content.mainContent.map((ele)=>{
                            return(
                                <div className='pb-4'>
                                <h2 className="body-heading ">{ele.heading}</h2>
                                <p className="body-paragraph">{ele.para}</p>
                                {
                                    ele.img &&
                                    <div className='w-100 text-center pt-3 pb-5'>
                                        <img src={ele.img} alt='thumbnail' className='img-fluid' style={{maxHeight:"500px"}}/>
                                    </div>
                                }
                                </div>
                            )
                        })
                    }

                </div>
              </div>
            </div>
          </>
  )
}

export default SpecializedSector