import React from 'react'
import HeroBanner from "../../components/Common Banner Component/HeroBanner";

const content = {
    heroHeading:"COMMERCIAL & LARGE SCALE",
    mainContent: [
        {
            heading: "Client Overview",
            para: "The Metro Toronto Convention Centre is a major exhibition facility in downtown Toronto, operating since 1984. It offers over 600,000 square feet of exhibit space, meeting rooms, and ballrooms for various trade shows, conferences, and concerts. Notably, it was one of the first convention centers in North America to achieve LEED certification, highlighting its commitment to eco-friendly practices."
        },
        {
            heading: "Problem",
            para: "With its high volume of traffic and long history, the MTCC required an update to its displays, which varied in size, orientation, and purpose. Outdated technology hindered effective messaging, negatively impacting the convention center's image and visitor experience."
        },
        {
            heading: "Solution",
            para: "Blue Fysh updated a wide range of displays at the MTCC with modern magnetic displays that provide high resolution and refresh rates. These seamless connections eliminate visible edges and frames, allowing for the creation of modular screens of any size without artifacts or lines. This solution perfectly meets the needs of contemporary displays and media players."
        },
        {
            heading: "Implementation",
            para: "A team of Blue Fysh’s top North American technicians visited the space to remove the old screens and update the areas with new configurations and displays, positioning the MTCC for future success."
        },
        {
            heading: "Resolution",
            para: "The MTCC now enjoys greater control over its media communication, featuring crisper images and higher refresh rates. This enables them to reflect their brand more accurately and communicate effectively with patrons and visitors. Thanks to Blue Fysh and our technicians, key aspects like color, speed, and resolution have all been upgraded, positioning the MTCC at the forefront of display technology."
        },
        {
            heading: "Conclusion",
            para: "Blue Fysh will continue to support the MTCC as a guiding partner in the evolving landscape of media technology. Always just a click away, we offer assistance, repairs, and technical solutions with a highly trained team. This project has helped the MTCC update its space to meet modern media demands, and Blue Fysh will be there to provide ongoing support.",
            vid:"/images/caseStudy/largeScale/vid-1.mp4"
        }
    ]       
}

function Commercial() {
  return (
          <>
            <div >
              
              <HeroBanner
                Paragraph={``}
                Heading={content.heroHeading}
                HighlightedSpan={""}
                // blueHiglightedText={content.category1}
                isBtn={false}
                bgImg={'/images/creatives/inner_case_st_banner.jpg'}
                isChevron={true}
            />
              <div className='main-content standard-padding-space'>
                <div className='container'>
                    {
                        content.mainContent.map((ele)=>{
                            return(
                                <div className='pb-4'>
                                <h2 className="body-heading ">{ele.heading}</h2>
                                <p className="body-paragraph">{ele.para}</p>
                                {
                                    ele.img &&
                                    <div className='w-100 text-center pt-3 pb-5'>
                                        <img src={ele.img} alt='thumbnail' className='img-fluid w-75'/>
                                    </div>
                                }
                                 {
                                    ele.vid &&
                                    <div className='w-100 text-center'>
                                         <video autoPlay loop muted controls playsInline controlslist="nodownload" style={{width:"70%"}}>
                                            <source src={ele.vid} type="video/mp4" />
                                        </video>
                                    </div>
                                }
                                </div>
                            )
                        })
                    }

                </div>
              </div>
            </div>
          </>
  )
}

export default Commercial